import {
  Box,
  Button,
  Container,
  Typography,
  styled,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import ReferPopupModal from "./ReferPopupModal";

const ContainerBox = styled(Container)({
  maxwidth: "100%",
  height: "calc(80vh - 74px)",
  padding: "15px 0px",
  display: "flex",
  gap: 25,
  flexDirection: "column",
  alignItems: "center",
});

const LinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  gap: 10,
  background: "#DDEAFC",
  width: "80%",
  borderRadius: 6,
  height: 50,
  padding: "2px 15px",
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
  "& > a": {
    textDecoration: "none",
    color: "#000",
    fontSize: 18,
    transition: "all 0.5s ease",
    cursor: "pointer",
    "&:hover": {
      color: "blue",
      fontSize: 19,
    },
  },
}));

const MainContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "80%",
  height: "80%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 15,
  backgroundColor: "#DDEAFC",
  backgroundImage: "url('/Moneybackground.png')",
  backgroundPosition: "center",
  backgroundSize: "cover",
  borderRadius: 6,
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const ImageCont = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "none",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  "&>img": {
    width: "100%",
    height: "100%",
    scale: "0.7",
    objectFit: "contain",
    [theme.breakpoints.up("md")]: {
      scale: ".8",
      objectFit: "cover",
    },
    [theme.breakpoints.up("lg")]: {
      scale: ".9",
    },
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: " 0px 35px",
  alignItems: "start",
  justifyContent: "space-between",
  gap: 10,
  [theme.breakpoints.up("lg")]: {
    width: "70%",
  },
}));

const TypographyContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  gap: 20,
  [theme.breakpoints.up("sm")]: {
    alignItems: "start",
    justifyContent: "center",
    textAlign: "left",
  },
  "& > #firsttypography": {
    fontSize: 35,
    fontWeight: 600,
    [theme.breakpoints.up("lg")]: {
      fontSize: 55,
      fontWeight: 800,
    },
  },
}));

const TypographyText = styled(Typography)(({ theme }) => ({
  fontSize: 25,
  fontWeight: 400,
  [theme.breakpoints.up("lg")]: {
    "& > span": {
      fontSize: 40,
      fontWeight: 600,
      color: "blue",
    },
  },
}));

const ButtonCont = styled(Button)({
  background: "#1B66C9",
  color: "#fff",
  width: "160px",
  height: "50px",
  borderRadius: 5,
  fontSize: 14,
  "&:hover": {
    background: "#5C76F8",
  },
});

const Hero = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <ContainerBox sx={{ marginTop: "148px" }}>
      <LinkContainer sx={{ boxShadow: 3 }}>
        <Link href="#refer">Refer</Link>
        <Link href="#benefits">Benefits</Link>
        <Link href="#faqs">FAQs</Link>
        <Link href="#supports">Supports</Link>
      </LinkContainer>
      <MainContainer sx={{ boxShadow: 4 }}>
        <TextContainer>
          <TypographyContainer>
            <TypographyText variant="h1" id="firsttypography">
              Let’s Learn & Earn
            </TypographyText>
            <TypographyText>
              Get a chance to win up-to{" "}
              <span
                style={{
                  fontSize: 30,
                  fontWeight: 500,
                  color: "blue",
                }}
              >
                Rs. 15,000
              </span>
            </TypographyText>
            <ButtonCont
              variant="contained"
              color="primary"
              onClick={handleOpen}
            >
              Refer Now
            </ButtonCont>
          </TypographyContainer>
        </TextContainer>
        <ImageCont>
          <img src="/heroimg.png" alt="heroimg" />
        </ImageCont>
      </MainContainer>
      <ReferPopupModal open={open} handleClose={handleClose} />
    </ContainerBox>
  );
};

export default Hero;
