import React from "react";
import Accordium from "./Accordium";
import CustomPaginationActionsTable from "./CustomPaginationActionsTable";
import {
  Box,
  Button,
  Container,
  Switch,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

const StyledContainer = styled(Container)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 40,
  justifyContent: "space-between",
  padding: "35px 20px",
});

const StyledBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 30,
  justifyContent: "start",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

const StyledButton = styled(Button)({
  background: "#1B66C9",
  color: "#fff",
  width: "160px",
  height: "50px",
  borderRadius: 5,
  fontSize: 14,
  "&:hover": {
    background: "#5C76F8",
  },
});

const Benefits = () => {
  const theme = useTheme();
  return (
    <StyledContainer id="benefits">
      <Typography
        variant="h4"
        sx={{
          fontSize: 24,
          [theme.breakpoints.up("lg")]: {
            fontSize: 34,
          },
        }}
      >
        What Are The <span style={{ color: "blue" }}>Referral Benefits?</span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "end",
          textAlign: "right",
        }}
      >
        Enrolled <Switch />
      </Box>
      <StyledBoxContainer>
        <Accordium />
        <CustomPaginationActionsTable />
      </StyledBoxContainer>
      <StyledButton>Refer Now</StyledButton>
    </StyledContainer>
  );
};

export default Benefits;
