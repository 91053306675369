import {
  AppBar,
  Toolbar,
  styled,
  Box,
  Link,
  Button,
  Typography,
  useTheme,
  createTheme,
} from "@mui/material";
import React from "react";
const themes = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mdd: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "#F5F5F5",
  boxShadow: "none",
  height: 74,
  padding: "15px",
  [theme.breakpoints.up("lg")]: {
    padding: "5px 15%",
  },
}));

const OptionWrapper = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: 28,
});

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: 20,
  "& > img": { width: 80, marginLeft: 15 },
  "& > #courses": { display: "none" },
  "& >.css-hyum1k-MuiToolbar-root": {
    padding: 0,
  },
  [theme.breakpoints.up("sm")]: {
    "& > #courses": { display: "flex" },
    "& > img": { width: 110 },
    "& >.css-hyum1k-MuiToolbar-root": {
      padding: "16px",
    },
    gap: 28,
  },
}));

const LinkWrapper = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: 28,
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
  justifyContent: "center",
  "& > a": {
    textDecoration: "none",
    color: "black",
    cursor: "pointer",
  },
}));

const ButtonsWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 28,
  "& > #login": {
    background: "#EAEDF1",
    color: "#000",
    "&:hover": {
      background: "#C0C0C0",
    },
  },
});

const ButtonCont = styled(Button)({
  background: "#1B66C9",
  color: "#fff",
  borderRadius: 5,
  fontSize: 10,
  [themes.breakpoints.up("mdd")]: {
    fontSize: 12,
    width: "fit-content",
  },
  "&:hover": {
    background: "#5C76F8",
  },
});

const Header = () => {
  const theme = useTheme();
  return (
    <>
      <StyledAppBar
        position="fixed"
        sx={{
          background: "#DDEAFC",
          display: "flex",
          justifyContent: "space-between",
          zIndex: 20,
          [theme.breakpoints.up("lg")]: {
            justifyContent: "center",
          },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 5,
            [theme.breakpoints.up("lg")]: {
              justifyContent: "center",
            },
          }}
        >
          <Typography sx={{ color: "#000" }} fontSize="sm">
            Navigate your ideal career path with tailored expert advice{" "}
          </Typography>
          <ButtonCont>contact expert</ButtonCont>
        </Toolbar>
      </StyledAppBar>
      <StyledAppBar position="fixed" sx={{ zIndex: 20, marginTop: "74px" }}>
        <Toolbar>
          <ImageWrapper>
            <img src="https://accredian.com/Rcimages/logo.png" alt="logo" />
            <ButtonCont id="courses">Courses</ButtonCont>
          </ImageWrapper>

          <OptionWrapper>
            <LinkWrapper>
              <Link href="#refer">Refer & Earn</Link>
              <Link href="#benefits">Resources</Link>
              <Link href="https://accredian.com/About">About Us</Link>
            </LinkWrapper>
            <ButtonsWrapper>
              <ButtonCont id="login">Login</ButtonCont>
              <ButtonCont>Try For Free</ButtonCont>
            </ButtonsWrapper>
          </OptionWrapper>
        </Toolbar>
      </StyledAppBar>
    </>
  );
};

export default Header;
