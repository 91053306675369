import { Box, Button, Typography, styled, useTheme } from "@mui/material";
import React from "react";

const ContainerBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#DDEAFC",
  maxWidth: "100%",
  height: "100%",
  padding: "15px 0px",
  display: "flex",
  gap: 25,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

const ImageCont = styled(Box)({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& > img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const ButtonArea = styled(Button)(({ theme }) => ({
  background: "#1B66C9",
  color: "#fff",
  width: "120px",
  height: "50px",
  borderRadius: 5,
  fontSize: 14,
  "&:hover": {
    background: "#5C76F8",
  },
  [theme.breakpoints.up("lg")]: {
    width: "130px",
    height: "50px",
    fontSize: 16,
  },
}));

const Refer = () => {
  const theme = useTheme();

  return (
    <ContainerBox id="refer">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          color: "#000",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: "24px",
            [theme.breakpoints.up("lg")]: {
              fontSize: "34px",
            },
          }}
        >
          How Do <span style={{ color: "blue" }}>I Refer?</span>
        </Typography>
        <ImageCont>
          <img src="/howdoirefer.png" alt="howdoirefer" />
        </ImageCont>
        <ButtonArea>Refer Now</ButtonArea>
      </Box>
    </ContainerBox>
  );
};

export default Refer;
