import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React from "react";

const BodyContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 2,
  padding: 32, // equivalent to p: 4
  backgroundColor: "white", // equivalent to bgcolor: "white"
  borderRadius: 8,
  width: "600px",
});

const ImageContainer = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  padding: "8px",
  borderRadius: 6,
  alignItems: "center",
  justifyContent: "center",
  "&>img": {
    width: "100%",
    height: "100%",
    scale: "1",
    borderRadius: 6,
    objectFit: "cover",
  },
});

const TextFieldContainer = styled(Box)({
  display: "flex",
  width: "100%",
  alignItems: "center",
  gap: 12,
});

const ButtonArea = styled(Button)({
  height: 40,
  marginTop: 15,
  width: "150px",
  borderRadius: 6,
  display: "inline",
});

const ReferPopupModal = ({ open, handleClose }) => {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <BodyContainer sx={{ mx: "auto", my: "5vh" }}>
          <ImageContainer>
            <img src="/learnandreferimg.jpeg" alt="learnandreferimg" />
          </ImageContainer>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "blue", alignSelf: "center" }}
          >
            Refer a Friend & Get Rs.200 Discount!
          </Typography>
          <Typography variant="p" gutterBottom>
            Your Name and Email Address
          </Typography>
          <TextFieldContainer>
            <TextField
              label="Your Name"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              label="Your Email"
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </TextFieldContainer>
          <Typography variant="p" gutterBottom sx={{ marginTop: "10px" }}>
            Friend's Name and Email Address
          </Typography>
          <TextFieldContainer>
            <TextField
              label="Friend's Name"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              label="Friend's Email"
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <ButtonArea variant="contained" color="primary" fullWidth>
              Submit
            </ButtonArea>
            <Typography variant="p" gutterBottom sx={{ marginTop: "14px" }}>
              Refer to a friend and earn some amazing price!!!
            </Typography>
          </TextFieldContainer>
        </BodyContainer>
      </Modal>
    </div>
  );
};

export default ReferPopupModal;
